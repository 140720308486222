import echoJs from './echo-js';
import personSearchFac from '../modules/persons-search';

const CLASS_NAME_SEARCH = 'buha-persons-search';
const CLASS_NAME = 'buha-person';
const CLASS_NAME_HIDDEN = 'buha-person--hidden';
const CLASS_NAME_EVEN = 'buha-person--even';
const CLASS_NAME_ODD = 'buha-person--odd';
const KEYWORD_REGEX = /\S+/g;

function _matchData(data, keywords) {
	const foundKw = [];

	if (typeof data === 'object') {
		const keys = Object.keys(data);
		for (let i = 0; i < keys.length; i += 1) {
			foundKw.push.apply(foundKw, _matchData(data[keys[i]], keywords));
		}
	} else if (Array.isArray(data)) {
		for (let j = 0; j < data.length; j += 1) {
			foundKw.push.apply(foundKw, _matchData(data[j], keywords));
		}
	} else if (typeof data === 'string') {
		for (let k = 0; k < keywords.length; k += 1) {
			if (data.toLowerCase().indexOf(keywords[k]) !== -1) {
				foundKw.push(keywords[k]);
			}
		}
	}

	return foundKw;
}

function matchData(data, keywords) {
	const fLen = _matchData(data, keywords)
		.filter((v, i, arr) => arr.indexOf(v) === i)
		.length;

	return fLen === keywords.length;
}

function normalizeKeywords(keywords) {
	if (typeof keywords === 'string') {
		keywords = keywords.match(KEYWORD_REGEX) || [];
	}

	if (!Array.isArray(keywords)) {
		throw new Error('"keywords" is not an array');
	}

	return keywords
		.filter((k) => k !== '')
		.map((k) => k.toLowerCase())
		.filter((v, i, arr) => arr.indexOf(v) === i);
}

const proto = {
	start(parentElem) {
		this._parentElem = parentElem;
		let categories = [];
		const elements = parentElem.queryAll(`.${CLASS_NAME}`);

		for (let i = 0; i < elements.length; i += 1) {
			const elem = elements[i];

			let personData = elem.getAttribute('data-person');
			personData = personData && JSON.parse(personData);
			if (personData) {
				categories.push(...personData.categories.map(c => c.title));
			}
		}

		categories = categories.filter((v, i, arr) => arr.indexOf(v) === i);

		const searchElem = parentElem.query(`.${CLASS_NAME_SEARCH}`);
		if (searchElem) {
			this._search = personSearchFac({
				onChange: this.onSearchChange.bind(this),
				categories: categories
			});
			this._search.start(searchElem);
			this._search.updateCount(parentElem.queryAll(`.${CLASS_NAME}`).length);
		}
	},

	onSearchChange(data) {
		let keywords = normalizeKeywords(data.keywords);
		keywords.push(data.category);
		keywords = normalizeKeywords(keywords);

		let personIndex = 0;

		const elems = this._parentElem.queryAll(`.${CLASS_NAME}`);
		for (let i = 0; i < elems.length; i += 1) {
			const elem = elems[i];

			let isMatch = false;
			if (keywords.length === 0) {
				isMatch = true;
			} else {
				let personData = elem.getAttribute('data-person');
				personData = personData && JSON.parse(personData);
				isMatch = personData && matchData(personData, keywords);
			}

			elem.classList[(isMatch ? 'remove' : 'add')](CLASS_NAME_HIDDEN);

			if (isMatch) {
				elem.classList.remove(CLASS_NAME_EVEN, CLASS_NAME_ODD);
				elem.classList.add(personIndex % 2 === 0 ? CLASS_NAME_EVEN : CLASS_NAME_ODD);
				personIndex += 1;
			}
		}

		this._search.updateCount(personIndex);

		echoJs.render();
	}
};

$(() => {
	$('.buha-content--nnbuhaarticlesandpersons_persons').each((i, element) => {
		(Object.create(proto)).start(element);
	});
});
