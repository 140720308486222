import variables from './_variables.js';
import throttle from 'lodash/throttle';
const $ = window.jQuery;

const FLAG_CLASS_NAME_PREFIX = 'buha-position-flag--';
const ACTIVE_CLASS_NAME = 'buha-imagewall--active';
const ITEM_ACTIVE_CLASS_NAME = 'buha-imagewall__item--active';
const CONTAINER_SELECTOR = '.buha-imagewall';
const ITEM_SELECTOR = '.buha-imagewall__item';

$(function () {
	function plugin() {
		const $imagewall = $(this);
		const $window = $(window);

		let $currentClass = '';

		function onStep1AndAboveEnter(e) {
			const x = e.clientX > ($window.width() / 2) ? 'r' : 'l';
			const y = e.clientY > ($window.height() / 2) ? 'b' : 't';

			if ($currentClass) {
				$imagewall.removeClass($currentClass);
			}
			$currentClass = FLAG_CLASS_NAME_PREFIX + x + y;
			$imagewall.addClass($currentClass);

			const $item = $(this);
			$item.find('img[data-src]').each(lazyLoadImage);
		}

		function lazyLoadImage() {
			const $image = $(this);
			const src = $image.attr('data-src');
			if (src) {
				$image
					.attr('src', src)
					.attr('data-src', null);
			}
		}

		function onStep0Clickout() {
			$imagewall
				.removeClass(ACTIVE_CLASS_NAME)
				.on('click', ITEM_SELECTOR, onStep0Enter)
				.find(`.${ITEM_ACTIVE_CLASS_NAME}`).removeClass(ITEM_ACTIVE_CLASS_NAME);
		}

		function onStep0Enter(e) {
			e.stopPropagation();

			const $item = $(this);
			$item.addClass(ITEM_ACTIVE_CLASS_NAME);

			$imagewall
				.addClass(ACTIVE_CLASS_NAME)
				.off('click', ITEM_SELECTOR, onStep0Enter);
		}

		function onStep0LinkClick(e) {
			const hasActiveParent = $(this).parents(`.${ITEM_ACTIVE_CLASS_NAME}`).length;
			if (!hasActiveParent) {
				e.preventDefault();
			}
		}

		let cacheIsStep1OrAbove;
		function updateEventListeners() {
			const isStep1OrAbove = $window.width() > variables.step0Max;
			if (cacheIsStep1OrAbove !== null && isStep1OrAbove === cacheIsStep1OrAbove) {
				return;
			}

			if (isStep1OrAbove) {
				$imagewall
					.on('mouseenter touchstart', ITEM_SELECTOR, onStep1AndAboveEnter)
					.off('click', onStep0Clickout)
					.off('click', ITEM_SELECTOR, onStep0Enter)
					.off('click', 'a', onStep0LinkClick);
			} else {
				$imagewall
					.off('mouseenter touchstart', ITEM_SELECTOR, onStep1AndAboveEnter)
					.on('click', onStep0Clickout)
					.on('click', ITEM_SELECTOR, onStep0Enter)
					.on('click', 'a', onStep0LinkClick);
			}
		}

		updateEventListeners();
		$window.on('resize', throttle(updateEventListeners, 100));
	}

	$(CONTAINER_SELECTOR).each(plugin);
});
