$(function () {
	const randomNum = Math.floor((Math.random() * 5) + 1);

	if (window.innerWidth > 680) {
		$('.buha-footer-background')
			.css('background-image',
				'url("/assets/img/footer/Burgfooter_v' + randomNum + '.png")'
			);
	}
});
