const CLASS_NAME = 'buha-persons-search';
const CLASS_NAME_KEYWORDS_FIELD = 'buha-persons-search__keywords-field';
const CLASS_NAME_CATEGORY_FIELD = 'buha-persons-search__category-field';

const DEFAULT_OPTIONS = {
	onChange() {
	},
	categories: []
};

const proto = {
	start(elem) {
		this._elem = elem;
		const keyElem = this._keyElem = elem.query(`.${CLASS_NAME_KEYWORDS_FIELD}`);
		const catElem = this._catElem = elem.query(`.${CLASS_NAME_CATEGORY_FIELD}`);

		keyElem.addEventListener('keyup', this.onChange.bind(this));

		// category
		const categories = this._options.categories;
		for (let i = 0; i < categories.length; i += 1) {
			const optElem = document.createElement('option');
			optElem.textContent = categories[i];
			catElem.appendChild(optElem);
		}
		catElem.addEventListener('change', this.onChange.bind(this));
	},

	onChange() {
		this._options.onChange({
			keywords: this._keyElem.value,
			category: this._catElem.value
		});
	},

	updateCount(count) {
		const elem = this._elem.query(`.${CLASS_NAME}__count`);
		elem.textContent = count;
	}
};

export default function personsSearchFactory(options) {
	const obj = Object.create(proto);
	obj._options = Object.assign({}, DEFAULT_OPTIONS, options || {});
	return obj;
}
