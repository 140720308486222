import variables from './_variables.js';
import throttle from 'lodash/throttle';

const $ = window.jQuery;
const $window = $(window);

const CLASS_PREFIX = 'buha-drilldown--';
const ACTIVE_CLASS = 'buha--drilldown';
const LOADING_CLASS = 'buha--drilldown-loading';
const HIDDEN_CLASS = 'hidden';

let drillDownCurrentDepth = 0;
let drillDownPreviousDepth = 0;
let drillDownCurrentLi;
let drillDownPreviousLi;

$(function () {
	const $html = $('html');
	const $toggleButton = $('.js-buha-drilldown-toggle-button');
	const $drillUpButton = $('.js-buha-drilldown-up-button');
	const $container = $('#buha-drilldown');
	const $navigation = $('#buha-drilldown-nav');
	const $content = $container.find('.buha-drilldown__content');
	const $pageMain = $('#buha-page-main');
	const navigationHtmlPartialUrl = $navigation.attr('data-partial-url');
	let navigationHtmlLoaded = false;
	let navigationHtmlIsLoading = false;

	const checkWindowSize = throttle(function () {
		if ($window.width() > variables.step0Max) {
			closeMenu();
		}
	}, 100);

	function loadNavigationHtml() {
		if (!navigationHtmlLoaded && !navigationHtmlIsLoading) {
			navigationHtmlIsLoading = true;
			$html.addClass(LOADING_CLASS);

			$.ajax(navigationHtmlPartialUrl, {dataType: 'html'}).done(data => {
				navigationHtmlLoaded = true;
				navigationHtmlIsLoading = false;
				$navigation
					.removeClass('buha-drilldown-nav--placeholder')
					.html(data);
				$html.removeClass(LOADING_CLASS);
			});
		}
	}


	// TODO add search form

	function changeDepth(newDepth) {
		if (newDepth === drillDownCurrentDepth) {
			return;
		}

		drillDownPreviousDepth = drillDownCurrentDepth;
		drillDownCurrentDepth = newDepth;

		$container
			.removeClass(CLASS_PREFIX + drillDownPreviousDepth)
			.addClass(CLASS_PREFIX + drillDownCurrentDepth);
	}

	function drillDown(newLi) {
		drillDownPreviousLi = drillDownCurrentLi;
		drillDownCurrentLi = newLi;

		drillDownCurrentLi.removeClass(HIDDEN_CLASS);
		drillDownCurrentLi.siblings().addClass(HIDDEN_CLASS);
		drillDownCurrentLi.find('ul').removeClass(HIDDEN_CLASS);
		changeDepth(drillDownCurrentDepth + 1);
	}

	function drillUp() {
		if (drillDownCurrentDepth === 0) {
			closeMenu();
			return;
		}

		const parentLi = drillDownCurrentLi && drillDownCurrentLi.parents('li').first();
		drillDownPreviousLi = drillDownCurrentLi;
		drillDownCurrentLi = parentLi;

		drillDownPreviousLi.siblings().removeClass(HIDDEN_CLASS);
		changeDepth(drillDownCurrentDepth - 1);
	}

	function reset() {
		$navigation.find('.hidden').removeClass(HIDDEN_CLASS);
		changeDepth(0);
		drillDownPreviousLi = null;
		drillDownCurrentLi = null;
	}

	function openMenu() {
		if (!navigationHtmlLoaded) {
			loadNavigationHtml();
		}

		reset();
		scrollTo(0, 0);
		$html.addClass(ACTIVE_CLASS);
		$container.attr('hidden', null);
		$toggleButton.attr('aria-expanded', 'true');
		$window.on('resize', checkWindowSize);
	}

	// Basic close menu function
	function closeMenu() {
		reset();
		$html.removeClass(ACTIVE_CLASS);
		$toggleButton.attr('aria-expanded', 'false');

		// Reset scroll position after focusing content to prevent unnecessary jump
		// snippet from: https://developer.mozilla.org/en-US/docs/Web/API/Window.scrollY
		const supportPageOffset = window.pageXOffset !== undefined;
		const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
		const scrollY = supportPageOffset ? window.pageYOffset :
			isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
		$pageMain.focus();
		$window
			.scrollTop(scrollY)
			.off('resize', checkWindowSize);
	}

	function handleTap(e) {
		e.stopPropagation();

		const newLi = $(this).closest('li');

		const isDifferent = !drillDownCurrentLi || newLi[0] !== drillDownCurrentLi[0];
		const hasSub = newLi.find('ul').length;

		if (isDifferent && hasSub) {
			drillDown(newLi);
		} else if (this.href) {
			window.location.href = this.href;
		}
	}

	$toggleButton
		.attr({
			role: 'button',
			'aria-controls': 'buha-main-nav',
			'aria-expanded': 'false'
		})
		.on('tap', e => {
			e.preventDefault();

			const isExpanded = $toggleButton.attr('aria-expanded') === 'true';

			if (isExpanded) {
				closeMenu();
			} else {
				openMenu();
			}
		});

	$navigation.on('tap', 'a, .js-buha-drilldown-spacer', handleTap);
	$drillUpButton.on('tap', drillUp);
	$container.on('click', closeMenu);
	$content.on('click', e => e.stopPropagation());

	setTimeout(loadNavigationHtml, 2000);
});
