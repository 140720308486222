const $ = window.jQuery;

const lang = document.documentElement.lang;
const CONTAINER_SELECTOR = '.buha-layout';
const DOCUMENT_MODIFIER_CLASS_NAME = 'buha--cookie-banner';
const CLASS_NAME = 'buha-cookie-banner';
const DETAILS_LINK_CLASS_NAME = 'buha-cookie-banner__details-link';

const COOKIE_EXPIRE_DAYS = 365;
const COOKIE_NAME = 'buha-cbr';
const COOKIE_VALUE = '1';

const label = lang === 'de' ?
	'Indem Sie diese Website benutzen, stimmen Sie der Nutzung von Cookies gemäß unseren Richtlinien zu.' :
	'By using this site, you agree to the use of cookies in accordance with our guidelines.';
const detailsLinkUrl = lang === 'de' ? '/impressum/' : '/en/impressum/';
const detailsLinkLabel = lang === 'de' ? 'Mehr erfahren' : 'Learn more';

$(() => {
	function plugin() {
		const $html = $('html');
		const $container = $(this);
		const $banner = $(`<div class="${CLASS_NAME}" />`);

		$html.addClass(DOCUMENT_MODIFIER_CLASS_NAME);

		$banner
			.html(`
				<p>
					${label}
					<button role="button">OK</button>
					<a class="${DETAILS_LINK_CLASS_NAME}" target="_blank" href="${detailsLinkUrl}">${detailsLinkLabel}</a>
				</p>
			`)
			.on('click', 'a', e => {
				e.stopPropagation();
			})
			.on('click', e => {
				window.Cookies.set(COOKIE_NAME, COOKIE_VALUE, {expires: COOKIE_EXPIRE_DAYS});
				$banner.remove();
				$html.removeClass(DOCUMENT_MODIFIER_CLASS_NAME);
			})
			.prependTo($container);
	}

	if (window.Cookies.get(COOKIE_NAME) !== COOKIE_VALUE) {
		$(CONTAINER_SELECTOR).each(plugin);
	}
});
