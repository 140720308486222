import fancySelect from './article-filter/fancy-select';

const SELECTOR = '.js-buha-article-filter';
const INPUT_ELEMENT_SELECTOR = '.js-buha-article-filter__input';
const $ = window.jQuery;

const urlSegmentTranslationMap =
	document.documentElement.lang === 'de' ?
	{
		courseAbschlussJahr: 'abschluss-jahr',
		courseSemester: 'semester',
		year: 'jahr',
		person: 'person',
		eventType: 'veranstaltungs-typ',
		projectType: 'projekt-typ',
		projectYear: 'projekt-jahr',
		projectPerson: 'projekt-person',
		publicationYear: 'publikations-jahr'
	} : {
		courseAbschlussJahr: 'degree-year',
		courseSemester: 'semester',
		year: 'year',
		person: 'person',
		eventType: 'event-type',
		projectType: 'project-type',
		projectYear: 'project-year',
		projectPerson: 'project-person',
		publicationYear: 'publication-year'
	};

const idToTypeMap = {
	'articles-filter-year': 'year',
	'articles-filter-person': 'person',
	'articles-filter-event-type': 'eventType',
	'articles-filter-project-type': 'projectType',
	'articles-filter-project-year': 'projectYear',
	'articles-filter-project-person': 'projectPerson',
	'articles-filter-publication-year': 'publicationYear',
	'articles-filter-course-semester': 'courseSemester',
	'articles-filter-course-abschluss-jahr': 'courseAbschlussJahr'
};

const urlGeneratorMap = {
	year: val => {
		val = parseInt(val, 10);
		return !!val && val > 1900 && val < 3000 && `${urlSegmentTranslationMap.year}/${val}/`;
	},
	projectYear: val => {
		val = parseInt(val, 10);
		return !!val && val > 1900 && val < 3000 && `${urlSegmentTranslationMap.projectYear}/${val}/`;
	},
	publicationYear: val => {
		val = parseInt(val, 10);
		return !!val && val > 1900 && val < 3000 && `${urlSegmentTranslationMap.publicationYear}/${val}/`;
	},
	person: val => !!val && val !== '0' && `${urlSegmentTranslationMap.person}/${val}/`,
	eventType: val => !!val && `${urlSegmentTranslationMap.eventType}/${val}/`,
	projectType: val => !!val && `${urlSegmentTranslationMap.projectType}/${val}/`,
	projectPerson: val => !!val && val !== '0' && `${urlSegmentTranslationMap.projectPerson}/${val}/`,
	courseAbschlussJahr: val => !!val && `${urlSegmentTranslationMap.courseAbschlussJahr}/${val}/`,
	courseSemester: val => !!val && `${urlSegmentTranslationMap.courseSemester}/${val}/`
};


function plugin() {
	const $container = $(this);
	const $inputFields = $container.find(INPUT_ELEMENT_SELECTOR);
	const locationBase = $container.attr('action');

	function handleChange() {
		let url = locationBase;
		$inputFields.each(function () {
			const $inputField = $(this);
	const id = $inputField.attr('id');
			if (id && $inputField.val()) {
	const type = idToTypeMap[id];
				if (type) {
					url += urlGeneratorMap[type]($inputField.val()) || '';
				}
	}
		});

		window.setTimeout(function () {
			window.location.href = url;
		}, 0);
	}

	$inputFields.each(function () {
		const $inputField = $(this);
	fancySelect($inputField);
	$inputField.on('change', e => {
		e.preventDefault();
			handleChange();
	});
	});
}

$(function () {
	$(SELECTOR).each(plugin);
});
