import variables from './_variables.js';
const $ = window.jQuery;

function plugin() {
	const linkElement = this;

	if (linkElement.href.indexOf('vimeo.com/') > -1) {
		linkElement.className += ' buha-magnific-popup mfp-iframe';
	}
}

$(() => $(variables.imageLinkSelector).each(plugin));
