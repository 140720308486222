import variables from './_variables.js';
import 'neonaut-tx-nnfeedit/Resources/Public/JavaScript/handleEditDone.js';
import 'neonaut-tx-nnfeedit/Resources/Public/JavaScript/registerIframeAsTypo3GlobalProxySource.js';

const $ = window.jQuery;
const PARAMETER_REGEX = /frontendEditing/i;
const PARAMETER_REPLACEMENT = 'isFrontendEditing=1&returnUrl=' + encodeURIComponent(variables.frontendEditingRedirectUrl);

$(document).ready(() => {
	$(variables.frontendEditingLinkSelector).each(function () {
		const $link = $(this);

		$link
			.magnificPopup({
				items: {
					src: $link.attr('href'),
					type: 'iframe'
				},
				iframe: {
					markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
					'</div>' // HTML markup of popup, `mfp-close` will be replaced by the close button
				},
				callbacks: {
					close() {
						$('html, body').css('overflow', 'auto');
					},
					change: function () {
						TXNNFEEDIT.registerIframeAsTypo3GlobalProxySource($(this.content).find('iframe')[0], {});
					}
				}
			})
			.attr('href', $link.attr('href').replace(PARAMETER_REGEX, PARAMETER_REPLACEMENT))
			.on('click tap touchend', () => $('html, body').css('overflow', 'hidden'));
	});

	if (window.location.href.indexOf('buha-frontend-editing-edit-article=true') !== -1) {
		$('.buha-frontend-editing-link--edit-article').first().magnificPopup('open');
	}
});

window.BUHA = window.BUHA || {};
window.BUHA.onFeEditingFinished = () => window.location.reload();
