import variables from './_variables.js';
const $ = window.jQuery;

function plugin() {
	const $toggleElement = $(this);
	const $targetElement = $toggleElement.find(variables.loginBoxTargetSelector);

	function toggleState() {
		let currentState = $targetElement.hasClass(variables.loginBoxActiveClassName);
		let fn = `${currentState ? 'remove' : 'add'}Class`;
		$toggleElement[fn](variables.loginBoxActiveClassName);
		$targetElement[fn](variables.loginBoxActiveClassName);

		if (!currentState) {
			$targetElement.find(':focusable').first().focus();
		}
	}

	$toggleElement.on('click', e => {
		e.preventDefault();
		toggleState();
	});
	$targetElement.on('click', e => {
		e.stopPropagation();
	});

	if ($targetElement.find('.buha-login-box__error-message').length) {
		$targetElement.addClass(variables.loginBoxErrorClassName);
		toggleState();
	}
}

$(() => $(variables.loginBoxToggleSelector).each(plugin));
