// Lazy-loading images with data-* attributes
// https://github.com/toddmotto/echo
import echoJsFac from 'echo-js';

const echoJs = echoJsFac(window);
export default echoJs;

echoJs.init({
	offset: 100,
	unload: true
});
