const $ = window.jQuery;
const $document = $(document);

const CLASS_NAME = 'buha-translation-info-popup';
//const CLASS_NAME_FOR_EVENT = 'buha-event-info-popup';

const COOKIE_EXPIRE_DAYS = 365;
const COOKIE_NAME = 'buha-tip';
//const COOKIE_NAME_FOR_EVENT = 'buha-event-tip';
const COOKIE_VALUE = '1';
//const COOKIE_VALUE_FOR_EVENT = '3';

const LABEL = '<h1>English version</h1>' +
	'<p>Please note: not all the content is available in english. We are working on it.</p>';
const LABEL_KEEP_HIDDEN = 'Do not show the message again';
//const LABEL_KEEP_HIDDEN_DE = 'Diese Meldung nicht mehr anzeigen';
const LABEL_DISMISS = 'Ok';

$(() => {
	const isEnglish = document.documentElement.lang === 'en';
	//const isHomepage = document.body.classList.contains('buha-uid--1');

	const isNew = window.Cookies.get(COOKIE_NAME) !== COOKIE_VALUE;
	//const isNewForEvent = window.Cookies.get(COOKIE_NAME_FOR_EVENT) !== COOKIE_VALUE_FOR_EVENT;
	//
	//let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

	//let frame = '';
	//if (width < 1000) {
	//	frame = '<iframe frameborder="0" width="300" height="510" src="https://burg-halle.de/~st3894/ja2k19/mobil.html?v=3"></iframe>';
	//} else {
	//	frame = '<iframe frameborder="0" width="800" height="425" src="https://burg-halle.de/~st3894/ja2k19/index.html?v=3"></iframe>';
	//}

	//const isNoPopupLink = window.location.hash === '#nopopup' || window.location.search === '?nopopup';
	//if (isNoPopupLink) {
	//	window.history.replaceState(null, null, '/');
	//}
	//
	//if (isNewForEvent && isHomepage && !isNoPopupLink) {
	//	$.magnificPopup.open({
	//		items: {
	//			src: `
	//				<div class="${CLASS_NAME_FOR_EVENT}">
	//					<div class="buha-event-info-popup__embed-container buha-event-info-popup__embed-container--ratio">
	//						${frame}
	//					</div>
	//				<div class="buha-event-info-popup__confirm">
	//					<a class="${CLASS_NAME_FOR_EVENT}__dismiss-button" href="#">${LABEL_DISMISS}</a>
	//					<form >
	//						<label>
	//							<input type="checkbox" class="${CLASS_NAME_FOR_EVENT}__keep-hidden-checkbox">
	//							<span class="${CLASS_NAME_FOR_EVENT}__keep-hidden-label">${isEnglish ? LABEL_KEEP_HIDDEN : LABEL_KEEP_HIDDEN_DE}</span>
	//						</label>
	//					</form>
	//				</div>
	//				</div>
	//			`,
	//			type: 'inline',
	//		},
	//		type: 'inline',
	//	});
	//
	//	$document
	//		.on('click', `.${CLASS_NAME_FOR_EVENT}__dismiss-button`, e => {
	//			e.preventDefault();
	//			$.magnificPopup.close();
	//		})
	//		.on('change', `.${CLASS_NAME_FOR_EVENT}__keep-hidden-checkbox`, e => {
	//			window.Cookies.set(COOKIE_NAME_FOR_EVENT, $(e.target).prop('checked') ? COOKIE_VALUE_FOR_EVENT : null, {expires: COOKIE_EXPIRE_DAYS});
	//		});
	//}


	if (isEnglish && isNew) {
		$.magnificPopup.open({
			items: {
				src: `
					<div class="${CLASS_NAME}">
						${LABEL}
						<form>
							<label>
								<input type="checkbox" class="${CLASS_NAME}__keep-hidden-checkbox">
								<span class="${CLASS_NAME}__keep-hidden-label">${LABEL_KEEP_HIDDEN}</span>
							</label>
						</form>
						<a class="${CLASS_NAME}__dismiss-button" href="#">${LABEL_DISMISS}</a>
					</div>
				`,
				type: 'inline'
			},
			type: 'inline'
		});

		$document
			.on('click', `.${CLASS_NAME}__dismiss-button`, e => {
				e.preventDefault();
				$.magnificPopup.close();
			})
			.on('change', `.${CLASS_NAME}__keep-hidden-checkbox`, e => {
				window.Cookies.set(COOKIE_NAME, $(e.target).prop('checked') ? COOKIE_VALUE : null, {expires: COOKIE_EXPIRE_DAYS});
			});
	}
});
