const CLASS_NAME = 'buha-fancy-select';


const $globalEventHub = $({});

function plugin($select) {
	const id = $select.attr('id');
	const $options = $select.children('option');
	const $container = $(`<span class="${CLASS_NAME}" />`);
	const $triggerElement = $(`label[for="${id}"]`);
	const $list = $(`<ul class="${CLASS_NAME}__options"></ul>`);
	const initialLabel = $triggerElement.text();

	function updateLabel($option) {
		const hasValue = $option.length && $option.val() && $option.val() !== '0';

		$triggerElement[(hasValue ? 'add' : 'remove') + 'Class'](CLASS_NAME + '--has-value');
		$triggerElement.text(hasValue ? $option.text() : initialLabel);
	}

	$options.each((i, option) => {
		const $option = $(option);
		$list.append(`
			<li class="${CLASS_NAME}__option" data-value="${$option.val()}">
				${$option.text()}
			</li>
		`);
	});

	$container
		.append($triggerElement)
		.append($list)
		.insertAfter($select);

	$list
		.on('click', '.' + CLASS_NAME + '__option', function () {
			const $option = $(this);
			const val = $option.attr('data-value');
			$select.val(val);
			updateLabel($select.find('option:selected'));
			$container.removeClass(CLASS_NAME + '--active');
			$globalEventHub.triggerHandler('reset', {triggerContainer: $container});
			$select.trigger('change');
		});

	$triggerElement
		.addClass(CLASS_NAME + '__trigger-element')
		.on('click', function () {
			$globalEventHub.triggerHandler('deactivate', {triggerContainer: $container});
			$container.toggleClass(CLASS_NAME + '--active');
		});

	$globalEventHub
		.on('deactivate', function (e, data) {
			if (data.triggerContainer !== $container) {
				$container.removeClass(CLASS_NAME + '--active');
			}
		})
		.on('reset', function (e, data) {
			if (data.triggerContainer !== $container) {
				updateLabel($select.find('options').first());
			}
		});

	updateLabel($select.find('option:selected'));
}

export default plugin;
