module.exports = {
	step0Max: 660,
	step1Max: 900,
	step2Max: 1000,
	frontendEditingRedirectUrl: '/typo3conf/ext/nn_buha_base/feEditingLightboxRedirect.html',
	frontendEditingLinkSelector: '.buha-frontend-editing-link, .buha-extranet-add-page-link',
	imageLinkSelector: '.js-buha-image-link',
	loginBoxToggleSelector: '.js-buha-login-box__toggle',
	loginBoxTargetSelector: '.js-buha-login-box__target',
	loginBoxActiveClassName: 'js-buha-login-box--active',
	loginBoxErrorClassName: 'buha-login-box--error'
};
