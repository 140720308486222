const SELECTOR = '.js-buha-masonry-layout';

const $ = window.jQuery;
window.Masonry = require('masonry-layout');
require('imagesloaded');

$(function () {
	// Initialize - Masonry -
	const elem = document.querySelector(SELECTOR);
	if (elem !== null) {
		const masonry = new Masonry(elem, {
			itemSelector: '.buha-article--teaser',
			gutter: 0
			//isAnimated: true
		});

		// layout when images loaded
		$(SELECTOR).imagesLoaded(function () {
			masonry.layout();
		});

		$('nav[role=navigation]:has(.f3-widget-paginator)').insertAfter('.buha-articles');
	}
});
