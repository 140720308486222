const $ = window.jQuery;

const translationMap =
	document.documentElement.lang === 'de' ?
	{
		tClose: 'Schließen (Esc)', // Alt text on close button
		tLoading: 'Lädt …', // Text that is displayed during loading. Can contain %curr% and %total% keys
		gallery: {
			tPrev: 'Vorheriges (Linke Pfeiltaste)', // Alt text on left arrow
			tNext: 'Nächstes (Rechte Pfeiltaste)', // Alt text on right arrow
			tCounter: '%curr% / %total%' // Markup for "1 of 7" counter
		},
		image: {
			tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message when image could not be loaded
		}
	} : {
		tLoading: 'Loading …', // Text that is displayed during loading. Can contain %curr% and %total% keys
		gallery: {
			tCounter: '%curr% / %total%' // Markup for "1 of 7" counter
		}
	};

$.extend(true, $.magnificPopup.defaults, translationMap);

$(function () {
	$('.buha-contents, .buha-gallery')
		.magnificPopup({
			delegate: '.buha-magnific-popup', // the selector for gallery item
			closeBtnInside: false,
			gallery: {
				enabled: true
			},
			tLoading: '',
			removalDelay: 500, // delay removal by X to allow out-animation
			callbacks: {
				beforeOpen() {
					// just a hack that adds mfp-anim class to markup
					this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
					this.st.mainClass = ''; // 'mfp-3d-unfold' 'mfp-zoom-in' this.st.el.attr('data-effect')
				}
			},
			image: {
				cursor: 'null'
			},
			type: 'image'
		});
});
