const $ = window.jQuery;
const HIDE_CLASS_NAME = 'buha-nav1-standalone-wrapper--hidden';

$(function () {
	const $element = $('.buha-nav1-standalone-wrapper');

	$('.buha-nav__0').on({
		mouseenter() {
			$element.addClass(HIDE_CLASS_NAME);
		},
		mouseleave() {
			$element.removeClass(HIDE_CLASS_NAME);
		}
	});
});
