require('dom4');

window.$ = window.jQuery = require('jquery');

// tap event
require('tappy-js');

// jquery ui
require('jquery-ui/ui/core.js');
require('jquery-ui/ui/position.js');
require('jquery-ui/ui/widget.js');
require('jquery-ui/ui/menu.js');
require('jquery-ui/ui/autocomplete.js');

// lightbox
require('magnific-popup/dist/jquery.magnific-popup.js');
